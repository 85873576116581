
import request from '@/services/interceptor.js'



// -----------------------------
// Ordini
// -----------------------------

export const get_initOrdine = (par) => {
    return request({
        url: '/ordini/init/' + par,
        method: 'get',
    })
};

export const new_ordine = (par) => {
    return request({
        url: '/ordini/new/' + par,
        method: 'get',
    })
};
export const del_ordine = (par) => {
    return request({
        url: '/ordini/' + par,
        method: 'delete'
    })
};

export const duplica_ordine = (par) => {
    return request({
        url: '/ordini/duplica/' + par,
        method: 'get',
    })
};
export const get_listaOrdini = (par) => {
    return request({
        url: '/ordini/lista/' + par,
        method: 'get'
    })
};
export const get_datiOrdine = (par) => {
    return request({
        url: '/ordini/dati/' + par,
        method: 'get'
    })
};


export const get_stampaOrdine = (par) => {
    return request({
        url: '/ordini/stampa/' + par,
        method: 'get',
    })
};

export const get_stampaCheckAvvisi = (par) => {
    return request({
        url: '/ordini/stampa/checkavvisi/' + par,
        method: 'get',
    })
};



// Conferma ordine
export const get_generaConfermaOrdine = (par) => {
    return request({
        url: '/ordini/conferma/genera/' + par,
        method: 'get'
    })
};

export const set_noteConfermaOrdine = (data) => {
    return request({
        url: '/ordini/conferma/note',
        method: 'post',
        data: data
    })
};

export const get_annullaConfermaOrdine = (par) => {
    return request({
        url: '/ordini/conferma/sblocca/' + par,
        method: 'get'
    })
};

export const get_articoliObsoletiConfermaOrdine = (par) => {
    return request({
        url: '/ordini/conferma/checkobsoleti/' + par,
        method: 'get'
    })
};

export const get_avvisiConfermaOrdine = (par) => {
    return request({
        url: '/ordini/conferma/avvisi/' + par,
        method: 'get'
    })
};




// Email
export const get_mailOrdine = (par) => {
    return request({
        url: '/ordini/email/' + par,
        method: 'get'
    })
};

export const send_mailOrdine = (par, data) => {
    return request({
        url: '/ordini/email/' + par,
        method: 'post',
        data: data
    })
};




// Utilita
export const get_listini = (par) => {
    return request({
        url: '/ordini/utilita/listino/' + par,
        method: 'get',
    })
};

export const set_listino = (par) => {
    return request({
        url: '/ordini/utilita/listino/' + par,
        method: 'get',
    })
};

export const get_timeLine = (par) => {
    return request({
        url: '/ordini/utilita/timeline/' + par,
        method: 'get',
    })
};

export const set_convertiOrdine = (par) => {
    return request({
        url: '/ordini/utilita/convertiordine/' + par,
        method: 'get',
    })
};

export const get_impostazioni = () => {
    return request({
        url: '/ordini/utilita/impostazioni/',
        method: 'get',
    })
};

export const set_impostazioni = (par) => {
    return request({
        url: '/ordini/utilita/impostazioni/',
        method: 'post',
        data: par
    })
};



// Infoazienda
export const get_abbinamentiInfoAzienda = (par) => {
    return request({
        url: '/ordini/infoazienda/abbinamenti/' + par,
        method: 'get'
    })
};

export const del_abbinamentiInfoAzienda = (par) => {
    return request({
        url: '/ordini/infoazienda/abbinamenti/' + par,
        method: 'delete'
    })
};

export const get_abbinaConfermaInfoAzienda = (par) => {
    return request({
        url: '/ordini/infoazienda/abbina/' + par,
        method: 'get'
    })
};

export const get_stampaConfermaInfoAzienda = (par) => {
    return request({
        url: '/ordini/infoazienda/stampa/' + par,
        method: 'get'
    })
};




// Testata
export const get_testataOrdine = (par) => {
    return request({
        url: '/ordini/testata/' + par,
        method: 'get'
    })
};
export const save_testataOrdine = (par) => {
    return request({
        url: '/ordini/testata',
        method: 'put',
        data: par
    })
};

export const get_clientiOrdini = (par) => {
    return request({
        url: '/ordini/testata/clienti/' + par,
        method: 'get'
    })
};


export const del_clientiOrdini = (par) => {
    return request({
        url: '/ordini/testata/clienti/' + par,
        method: 'delete'
    })
};

export const save_clientiOrdini = (par) => {
    return request({
        url: '/ordini/testata/clienti',
        method: 'post',
        data: par
    })
};

export const get_totaliTestata = (par) => {
    return request({
        url: '/ordini/testata/totali/' + par,
        method: 'get'
    })
};
export const save_totaliTestata = (par) => {
    return request({
        url: '/ordini/testata/totali',
        method: 'post',
        data: par
    })
};

export const get_testataComuni = () => {
    return request({
        url: '/ordini/testata/comuni',
        method: 'get'
    })
};
export const get_testataTrasmitLimiti = () => {
    return request({
        url: '/ordini/testata/trasmittanza/limiti',
        method: 'get'
    })
};
export const get_testataCosti = (par) => {
    return request({
        url: '/ordini/testata/costi/' + par,
        method: 'get'
    })
};

export const get_testataUtenti = () => {
    return request({
        url: '/ordini/testata/utenti',
        method: 'get'
    })
};



// Dettaglio
export const get_dettaglioOrdine = (par) => {
    return request({
        url: '/ordini/dettaglio/' + par,
        method: 'get'
    })
};
export const new_rigoDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio',
        method: 'post',
        data: par
    })
};
export const del_dettaglioOrdine = (par) => {
    return request({
        url: '/ordini/dettaglio/' + par,
        method: 'delete'
    })
};
export const dup_dettaglioOrdine = (par) => {
    return request({
        url: '/ordini/dettaglio/duplica/' + par,
        method: 'get'
    })
};
export const get_dettaglioSerie = (par) => {
    return request({
        url: '/ordini/dettaglio/serie/' + par,
        method: 'get'
    })
};
export const get_dettaglioProgetti = (par) => {
    return request({
        url: '/ordini/dettaglio/progetti/' + par,
        method: 'get'
    })
};
export const get_totaliDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/totali/' + par,
        method: 'get'
    })
};

export const get_sezioniDettaglio = (par, data) => {
    return request({
        url: '/ordini/dettaglio/sezioni/' + par,
        method: 'post',
        data: data
    })
};

export const get_disegnoDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/disegno/' + par,
        method: 'get'
    })
};

export const get_spostaDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/sposta/' + par,
        method: 'get'
    })
};





// Voci extra
export const get_vociExtraOrdine = (par) => {
    return request({
        url: '/ordini/vociextra/' + par,
        method: 'get'
    })
};
export const del_rigoVociExtra = (par) => {
    return request({
        url: '/ordini/vociextra/' + par,
        method: 'delete'
    })
};
export const new_rigoVociExtra = (par) => {
    return request({
        url: '/ordini/vociextra',
        method: 'post',
        data: par
    })
};
export const get_coloriCoprifiloVociExtra = (par) => {
    return request({
        url: '/ordini/vociextra/coprifili/colori',
        method: 'post',
        data: par
    })
};
export const get_prezzoCoprifiloVociExtra = (par) => {
    return request({
        url: '/ordini/vociextra/coprifili/prezzo',
        method: 'post',
        data: par
    })
};



// Allegati
export const get_allegatiOrdine = (par) => {
    return request({
        url: '/ordini/allegati/' + par,
        method: 'get'
    })
};
export const del_allegatiOrdine = (par) => {
    return request({
        url: '/ordini/allegati/' + par,
        method: 'delete'
    })
};
export const new_allegatiOrdine = (par) => {
    return request({
        url: '/ordini/allegati',
        method: 'post',
        data: par
    })
};

export const upload_allegati = (par, data) => {
    return request({
        url: '/ordini/allegati/upload/' + par,
        method: 'post',
        data: data,
    })
};

export const download_allegati = (par) => {
    return request({
        url: '/ordini/allegati/download/' + par,
        method: 'get',
        responseType: 'blob'

    })
};





// Riepilogo serie
export const get_riepilogoSerie = (par) => {
    return request({
        url: '/ordini/riepilogoserie/' + par,
        method: 'get'
    })
};
export const del_riepilogoSerie = (par) => {
    return request({
        url: '/ordini/riepilogoserie/' + par,
        method: 'delete'
    })
};
export const new_riepilogoSerie = (par) => {
    return request({
        url: '/ordini/riepilogoserie',
        method: 'post',
        data: par
    })
};



// Varianti
export const get_varianteDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/varianti/' + par,
        method: 'get'
    })
};
export const del_varianteDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/varianti/' + par,
        method: 'delete'
    })
};
export const new_varianteDettaglio = (data) => {
    return request({
        url: '/ordini/dettaglio/varianti',
        method: 'post',
        data: data
    })
};

export const new_varianteDettaglioMultiple = (data) => {
    return request({
        url: '/ordini/dettaglio/varianti/multiple',
        method: 'post',
        data: data
    })
};

export const new_varianteProduzioneDettaglio = (data) => {
    return request({
        url: '/ordini/dettaglio/varianti/produzione',
        method: 'post',
        data: data
    })
};

export const get_categorieVariantiDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/varianti/categorie/' + par,
        method: 'get'
    })
};
export const get_articoliVariantiDettaglio = (par) => {
    return request({
        url: '/ordini/dettaglio/varianti/articoli/' + par,
        method: 'get'
    })
};

export const get_dettaglioVariantiPariCodice = (par) => {
    return request({
        url: '/ordini/dettaglio/varianti/paricodice/' + par,
        method: 'get'
    })
};

export const set_dettaglioVariantiPariCodice = (par, data) => {
    return request({
        url: '/ordini/dettaglio/varianti/paricodice/' + par,
        method: 'post',
        data: data
    })
};

export const set_dettaglioVariantiValidazione = (par, data) => {
    return request({
        url: '/ordini/dettaglio/varianti/articoli/valida/' + par,
        method: 'post',
        data: data
    })
};

export const save_dettaglioVariantiModifica = (data) => {
    return request({
        url: '/ordini/dettaglio/varianti/modifica',
        method: 'post',
        data: data
    })
};




// Ordini inviati

export const get_listaOrdiniInviati = (par) => {
    return request({
        url: '/ordini/inviati/' + par,
        method: 'get',
        loadingBar: true
    })
};

export const download_confermaOrdine = (par) => {
    return request({
        url: '/ordini/inviati/download/' + par,
        method: 'get',
        responseType: 'blob'

    })
};



// Testi
export const get_testiArchivioOrdine = (par, data) => {
    return request({
        url: '/ordini/testi/archivio',
        method: 'get',
        data: data
    })
};

export const save_testiArchivioOrdine = (data) => {
    return request({
        url: '/ordini/testi/archivio',
        method: 'post',
        data: data
    })
};

export const save_testiOrdine = (par, data) => {
    return request({
        url: '/ordini/testi/' + par,
        method: 'post',
        data: data
    })
};
