<template src="./articoliDettaglio.html"></template>

<script>
import {
  get_categorieVariantiDettaglio,
  get_articoliVariantiDettaglio,
  set_dettaglioVariantiValidazione,
} from "@/api/api.ordini.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    scheda: { type: Number, default: 0 },
    prog: { type: Number, default: 0 },
    progVariante: { type: Number, default: 0 },
    codCategoria: { type: String, default: "" },
    sezione: { type: Number, default: 11 },
    codMacroCategoria: { type: String, default: "" },
    variantiProduzione: { type: Array, default: () => [] },
    codArticolo: { type: String, default: "" },
    configDatiAgg: { type: Array, default: () => [] },
    vociExtra: { type: Boolean, default: false },
    qta: { type: Number, default: 0 },
  },

  data: () => ({
    aggiornaGriglia: false,
    attesa: false,
    attesaArticoli: false,

    datiCategorie: [],
    datiArticoli: [],

    datiVariantiProduzione: [],


    categoriaSel: {},
    codCatSel: "",
    codArtSel: {},

    pannelliEspansi: -1,

    qtaVar: 0,

    search: "",
  }),

  methods: {
    async caricaDati() {

      if (this.dialog == false) return;

      // Reset
      this.datiCategorie = [];
      this.datiArticoli = [];
      this.categoriaSel = {};
      this.codCategoriaSel = ""
      this.codArtSel = {};
      this.datiVariantiProduzione = [];
      this.pannelliEspansi = null;
      this.search = "";
      this.qtaVar = this.qta;

      // Controllo nuovo
      if (this.idPrev == 0) {
        return;
      }

      this.attesa = true;

      let param;

      try {
        // Controllo voci extra
        if (this.vociExtra) {
          // ------------------
          // --- VOCI EXTRA ---
          // ------------------
          let res = await get_categorieVariantiDettaglio(
            "vociExtra/" + this.idPrev
          );

          this.datiCategorie = res.data;
        } else {
          // ------------------
          // ---- VARIANTI ----
          // ------------------

          // Controllo macrocategoria
          if (this.codMacroCategoria.length > 0)
            param =
              "filtromacrocategoria/" +
              this.idPrev +
              "/" +
              this.scheda +
              "/" +
              this.prog +
              "/" +
              this.sezione +
              "/" +
              this.codMacroCategoria;
          else
            param =
              this.idPrev +
              "/" +
              this.scheda +
              "/" +
              this.prog +
              "/" +
              this.sezione +
              "/" +
              this.codCategoria;

          let res = await get_categorieVariantiDettaglio(param);
          this.datiCategorie = res.data;
        }

        // Se ho una sola catagoria carico subito gli articoli associati
        if (this.datiCategorie.length == 1) {
          this.caricaArticoli(this.datiCategorie[0]);
        }
        // Se apro con la lentina l'articolo, carico i dati
        else if (this.codArticolo) {
          let cat = this.datiCategorie.find((f) => f.CodCategoria == this.codCategoria);
          this.caricaArticoli(cat);
        }


      }
      catch (err) {
        console.log(this.$i2kFunctions.getErrorMsg(err));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(err));
      }

      this.attesa = false;
    },

    async caricaArticoli(item) {

      if (!!item == false)
        return

      this.categoriaSel = item
      this.codCategoriaSel = this.categoriaSel.CodCategoria;

      // Reset pannelli aperti
      this.pannelliEspansi = null;
      this.datiArticoli = [];
      this.datiVariantiProduzione = [];
      this.codArtSel = {};

      this.attesaArticoli = true;

      try {
        // -------------
        //  Origine voci extra
        // -------------
        if (this.vociExtra) {
          let res = await get_articoliVariantiDettaglio(
            "vociExtra/" + this.idPrev + "/" + this.codCategoriaSel
          );

          this.datiArticoli = res.data;
        } else {
          // -------------
          // Origine varianti
          // -------------
          let res = await get_articoliVariantiDettaglio(
            this.idPrev +
            "/" +
            this.scheda +
            "/" +
            this.prog +
            "/" +
            this.sezione +
            "/" +
            this.codCategoriaSel +
            "/" +
            this.progVariante +
            "/" +
            this.codMacroCategoria
          );

          this.datiArticoli = res.data;
        }

        if (!!this.datiArticoli == false) return;

        // Controllo articolo selezionato da varianti
        if (this.codArticolo) {
          this.selezionaArticolo(
            this.datiArticoli.find((f) => f.CodArticolo == this.codArticolo)
          );
        }

        // Ricavo gli articoli raggruppati
        let artRag = this.articoliRaggruppati;

        // Controllo se aprire il pannello dell'articolo selezionato
        if (this.codArticolo && this.datiArticoli.length > 0) {
          // Ciclo sui gruppi per ricavare l'indice del gruppo
          let indice = 0;
          for (let item in artRag) {
            if (item == this.codArtSel.CodSubCategoria) {
              break;
            }
            indice++;
          }

          // NOTA: devo usare nextTick perchè il render è gestito dalla computed
          this.$nextTick(() => (this.pannelliEspansi = indice));

          // NOTA: devo attendere qualche millisecondo
          let t = this;
          setTimeout(function () {
            t.scrollToArticolo();
          }, 300);
        } else {
          // Controllo se ho un solo pannello, lo apro
          if (Object.keys(artRag).length == 1)
            this.$nextTick(() => (this.pannelliEspansi = 0));
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      } finally {
        this.attesaArticoli = false;
      }
    },

    scrollToArticolo() {
      let el = document.getElementById("articolo" + this.codArtSel.RowId);

      // Controllo presenza elemento
      if (el) {
        // NOTA: devo attendere qualche millisecondo
        setTimeout(function () {
          el.scrollIntoView();
        }, 300);
      }
    },

    async salva() {
      // Controllo articolo
      if (Object.keys(this.codArtSel).length == 0) {
        this.$toast.alert("Nessun articolo selezionato");
        return;
      }


      if (this.vociExtra) {
        // -------------
        //  Origine voci extra
        // -------------

        let data = {
          CodArticolo: this.codArtSel.CodArticolo,
          Descrizione: this.codArtSel.Descrizione,
        };
        this.chiudi(data);

      }

      else {
        // -------------
        // Origine varianti
        // -------------


        // Validazione
        if ((await this.validaArticolo()) == false) return;

        // Attesa
        this.attesa = true;

        let data = {
          Variante: {
            IDPrev: this.idPrev,
            Scheda: this.scheda,
            Prog: this.prog,
            Sezione: this.sezione,
            ProgVariante: this.progVariante,
            CodArticolo: this.codArtSel.CodArticolo,
            CodCategoria: this.codCategoriaSel,
            CodMacroCategoria: this.codMacroCategoria,
            Descrizione: "",
            Qta: parseInt(this.qtaVar),
            Prezzo: 0,
          },
          VariantiProduzione: this.datiVariantiProduzione,
        };

        this.chiudi(data);
      }
    },

    async validaArticolo() {
      let arrValori = [];

      for (let i in this.getDatiAgg) {
        // Controllo presenza ArticoliCollegati
        if (this.getDatiAgg[i].ArticoliCollegati.length > 0 && this.getDatiAgg[i].ArticoliCollegati.indexOf(this.codArtSel.CodArticolo) == -1)
          continue

        arrValori.push(this.getValoreProduzione(this.getDatiAgg[i], i));
      }

      let par =
        this.idPrev +
        "/" +
        this.scheda +
        "/" +
        this.prog +
        "/" +
        this.sezione +
        "/" +
        this.progVariante;

      let data = {
        codArticolo: this.codArtSel.CodArticolo,
        QtaImpostata: this.qtaVar,
        ValoriImpostati: arrValori.join(";"),
      };

      let res = await set_dettaglioVariantiValidazione(par, data);

      if (res.data == "OK") return true;

      this.$toastFixed.alert(res.data);
      return false;
    },

    chiudi(data) {
      let aggiorna = !!data && Object.keys(data).length > 0;

      this.$emit("chiudiDialog", aggiorna, this.codArtSel, data);
    },

    selezionaArticolo(item) {
      if (!!item == false) return;

      // NOTA: il click verrebbe richiamato anche se l'utente clicca sulla combo interna
      if (this.codArtSel === item) return;

      // Memorizzo l'articolo selezionato
      this.codArtSel = item;

      // Reset
      this.datiVariantiProduzione = [];
      let filtro = [];

      // Filtro le varianti di produzione associate alla macrocategoria e sezione attuali
      filtro = this.variantiProduzione.filter(
        (f) =>
          f.CodMacroCategoria.toLowerCase() ==
          item.CodMacroCategoria.toLowerCase() && f.Sezione == this.sezione
      );

      // Deep copy
      let arr = JSON.parse(JSON.stringify(filtro));
      for (let key in arr) {
        // Controllo se sono presenti dati aggiuntivi associati solo all'articolo 
        if (this.getDatiAggVarProd(arr[key]["CodVarianteProduzione"], this.codArtSel.CodArticolo) == false)
          continue

        this.datiVariantiProduzione.push(arr[key]);
      }

    },

    getValoreProduzione(item, i) {

      let v = this.datiVariantiProduzione.find(
        (f) =>
          f.CodVarianteProduzione.toLowerCase() ==
          item.CodVarianteProduzione.toLowerCase()
      );

      return v ? v.Valore : "";
    },

    setValoreProduzione(item, valore) {

      if (typeof valore === "object") valore = valore.Valore;

      let v = this.datiVariantiProduzione.find(
        (f) =>
          f.CodVarianteProduzione.toLowerCase() ==
          item.CodVarianteProduzione.toLowerCase()
      );

      if (!!v == false) return;

      v.Valore = valore;
    },

    getValoriProduzione(item) {
      let v = this.codArtSel.ValoriProduzione.filter(
        (f) =>
          f.CodVarianteProduzione.toLowerCase() ==
          item.CodVarianteProduzione.toLowerCase()
      );

      return v;
    },

    cerca() { },

    tooltipVis(item) {
      return !!(item.UrlImage || item.TestoTooltip);
    },

    apriLink(item) {
      window.open(item.LinkTooltip, "_blank");
    },

    showDatiAgg(item) {
      let v1 = item.InserisciQta && !this.verificaQuote && !this.vociExtra;

      let v2 = this.getDatiAgg.length > 0;

      return v1 || v2;
    },

    getDatiAggArticoli(item) {

      let res = []

      for (let i = 0; i < this.getDatiAgg.length; i++) {
        // Controllo prenseza articoli collegati
        if (this.getDatiAgg[i].ArticoliCollegati.length > 0) {
          if (this.getDatiAgg[i].ArticoliCollegati.includes(item.CodArticolo)) {
            res.push(this.getDatiAgg[i])
          }
        }
        else {
          res.push(this.getDatiAgg[i])
        }
      }

      return res
    },

    getDatiAggVarProd(codVarProd, codArt) {
      let dtAgg = this.getDatiAgg

      // Controllo se sono presenti dati aggiuntivi
      if (dtAgg.length == 0)
        return true

      let filtroVarProd = dtAgg.find(f => f.CodVarianteProduzione == codVarProd)

      return filtroVarProd.ArticoliCollegati.includes(codArt) || filtroVarProd.ArticoliCollegati.length == 0

    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    articoliRaggruppati() {
      let filtroArticoli = [];

      if (!!this.datiArticoli == false) return [];

      // Controllo filtro
      if (this.search) {
        filtroArticoli = this.datiArticoli.filter(
          (item) =>
            item.Descrizione.toLowerCase().indexOf(this.search.toLowerCase()) >=
            0 ||
            item.CodArticolo.toLowerCase().indexOf(this.search.toLowerCase()) >=
            0
        );
      } else filtroArticoli = this.datiArticoli;

      // Raggruppo x CodSubCategoria
      let gruppi = this.$i2kFunctions.groupBy(
        filtroArticoli,
        "CodSubCategoria"
      );

      // Group by
      return gruppi;
    },

    getDatiAgg() {
      return this.configDatiAgg.filter(
        (f) => f.CodMacroCategoria == this.codArtSel.CodMacroCategoria
      );
    },

    getNoteArticolo() {
      if (this.getDatiAgg.length == 0) return "";

      return this.codArtSel.NoteArticolo;
    },

    verificaQuote() {
      return !!this.getDatiAgg.find((f) => f.InputType == "COMBOBOX");
    },

    getNoteCategoria() {
      if (!!this.categoriaSel && Object.keys(this.categoriaSel).length > 0)
        return this.categoriaSel.NoteWeb

      return ""

    }
  },

  watch: {
    dialog(val) {
      return !val || this.caricaDati();
    },
  },
};
</script>

<style scoped>
.altezzaContainer {
  height: 500px !important;
}
</style>
