<template src="./dettaglioOrdine.html"></template>

<script>
import DialogSezioni from "@/components/dialog/ordini/SezioniDettaglio";
import DialogArticoli from "@/components/dialog/ordini/ArticoliDettaglio";
import DialogPariCodice from "@/components/dialog/ordini/PariCodice";
import DialogCoprifili from "@/components/dialog/ordini/Coprifili";
import DialogModificaVar from "@/components/dialog/ordini/ModificaVariante";

import {
  new_rigoDettaglio,
  get_dettaglioOrdine,
  get_varianteDettaglio,
  get_dettaglioSerie,
  get_dettaglioProgetti,
  del_varianteDettaglio,
  get_sezioniDettaglio,
  get_totaliDettaglio,
  get_disegnoDettaglio,
  new_varianteDettaglio,
  new_varianteProduzioneDettaglio,
  new_varianteDettaglioMultiple,
} from "@/api/api.ordini.js";

export default {
  components: {
    DialogSezioni,
    DialogArticoli,
    DialogPariCodice,
    DialogCoprifili,
    DialogModificaVar,
  },

  props: {
    dialog: { type: Boolean, default: false },
    idPrev: { type: Number, default: 0 },
    scheda: { type: Number, default: 0 },
    prog: { type: Number, default: 0 }, // NOTA: serve anche x capire se sono in modifica
    bloccato: { type: Boolean, default: false },
  },

  data: () => ({
    datiDettaglio: {},
    datiVarianti: [],
    datiSerie: [],
    datiProgetto: [],
    datiAperture: [],
    datiSezioni: [],
    datiAvvisi: [],
    datiVariantiProduzione: [],
    datiBattute: [],
    datiAltManiglie: [],
    configDatiAgg: [],

    switchSpeciale: false,

    stepper: ["Tipologia", "Battuta profilo", "Coprifili", "Varianti"],
    step: 1,
    stepClickEnabled: false,
    stepColor: "",
    stepMax: 4,

    datiDettaglio_empty: {
      IDPrev: 0,
      Scheda: 0,
      Prog: 0,
      CodProgetto: "",
      Ubicazione: "",
      Riferimento: "",
      Qta: 0,
      L: 0,
      H: 0,
      CodSerie: "",
      Note: "",
      NoStampaDisegno: false,
      NoteQuote: "",
    },

    pagination: {
      sortBy: "Prog",
    },

    selectRowVarianti: [],
    progVariante: 0,
    sezione: 0,
    selectRowSezione: [],
    progVariante_insArticolo: 0,
    codCategoriaVariante_insArticolo: "",
    sezioneVariante_insArticolo: "",
    codMacroCategoria_insArticolo: "",
    qtaVar: 0,
    proprieta: "",
    codArticoloVariante: "",
    var_selezionata: {},
    checkVarianti: [],
    tipoCoprifiliElimina: "",
    tipoPariCodice: "",

    tipoBattutaItems: [],
    battuta: 0,

    progettoLibero: "LIBERO",
    progettoLibero_H: "LIBERO H",
    progettoLibero_MQ: "LIBERO MQ",

    pathImg: "",
    progDett: 0,

    caricaGrigliaDett: false,
    attesa: false,
    attesaDisegno: false,
    attesaSalva: false,
    attesaProgetti: false,
    attesaSerie: false,

    modificaValori: false,
    modificaValoriVarianti: false,

    dialogVariante: false,
    dialogSezioni: false,
    dialogArticoli: false,
    dialogPariCodice: false,
    dialogCoprifili: false,
    dialogModificaVar: false,

    textRules: [(v) => !!v || "Il campo è obbligatorio"],
    numericRules: [
      (v) => !!v || "Obbligatorio",
      (v) => v != 0 || "Obbligatorio",
    ],
    numericZeroRules: [
      (v) => (!!v || v === 0 ? true : "Il campo è obbligatorio"),
    ],
  }),

  methods: {
    // --------------------------------------------------------
    // ------------------------ DETTAGLIO -----------------------
    // --------------------------------------------------------

    async caricaDati() {
      if (this.dialog == false) return;

      // NOTA: quando apro si attiva la validazione
      this.$nextTick(() => this.$refs.formDettaglio.resetValidation());

      // Reset
      this.datiDettaglio = Object.assign({}, this.datiDettaglio_empty);
      this.datiVarianti = [];
      this.datiSerie = [];
      this.datiProgetto = [];
      this.datiVariantiProduzione = [];
      this.datiBattute = [];
      this.configDatiAgg = [];
      this.pathImg = "";
      this.selectRowVarianti = [];
      this.selectRowSezione = [];
      this.progVariante = 0;
      this.datiAperture = [];
      this.datiSezioni = [];
      this.datiAvvisi = [];
      this.datiAltManiglie = [];
      this.sezione = 0;
      this.progDett = this.prog;
      this.progVariante_insArticolo = 0;
      this.codCategoriaVariante_insArticolo = "";
      this.sezioneVariante_insArticolo = 0;
      this.codMacroCategoria_insArticolo = "";
      this.qtaVar = 0;
      this.step = 1;
      this.stepColor = "primary";
      this.modificaValori = false;
      this.modificaValoriVarianti = false;
      this.tipoBattutaItems = []
      this.attesaSalva = false;
      this.var_selezionata = {};
      this.checkVarianti = [];
      this.switchSpeciale = false;


      if (this.progDett == 0) {
        // --- NUOVO ---

        // NOTA: reimposto i valori perchè è resettato sopra con Object.assign
        this.datiDettaglio.IDPrev = this.idPrev;
        this.datiDettaglio.Scheda = this.scheda;
        this.datiDettaglio.Prog = this.progDett;

        // Carico le serie
        this.caricaSerie();

      }
      else {
        // --- MODIFICA ---

        this.stepClickEnabled = true;

        let param = this.idPrev + "/" + this.scheda + "/" + this.progDett;

        // Attesa
        this.attesa = true;

        try {
          // Dettaglio - Righe Varianti
          let res = await get_dettaglioOrdine(param);

          this.setDatiDettaglio(res.data);
        } catch (error) {
          console.log(this.$i2kFunctions.getErrorMsg(error));
        }
        this.attesa = false;
      }


    },

    async salva(chiudiFinestra, stepAvanti) {
      if (this.attesaSalva) return;

      // Controllo bloccato
      if (this.bloccato) {
        this.chiudi();
        return;
      }

      // Attesa
      this.$wait.open();
      this.attesaSalva = true;

      // Aggiorna griglia prev
      this.caricaGrigliaDett = true;

      let param = {
        Dettaglio: this.datiDettaglio,
        Sezioni: [this.selectRowSezione],
      };

      try {
        let res = await new_rigoDettaglio(param);

        // Memorizzo i dati
        this.setDatiDettaglio(res.data);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
      // Memorizzo il progressivo (serve su nuovo inserimento)
      this.progDett = this.datiDettaglio.Prog;

      // Dati sezione
      this.caricaDatiSezione();

      // Controllo chiusura
      if (chiudiFinestra) this.chiudi();

      // Controllo avanzamento form
      if (stepAvanti) this.nextStep();

      // Fine attesa
      this.$wait.close();
      this.attesaSalva = false;
    },

    salvaDaVariante() {
      // Reset varianti
      this.datiVarianti = [];
      this.checkVarianti = [];

      this.salva();
    },

    setDatiDettaglio(data) {
      this.datiDettaglio = data.Dettaglio;
      this.datiVarianti = data.Varianti;
      this.datiVariantiProduzione = data.VariantiProduzione;

      this.datiSerie = data.Serie;
      this.datiProgetto = data.Progetti;

      this.datiSezioni = data.Sezioni;
      this.datiAvvisi = data.Avvisi;
      this.datiBattute = data.Battute;
      this.configDatiAgg = data.ConfigDatiAgg;

      // Avvisi
      if (this.datiDettaglio.MsgAvviso)
        this.$toast.alert(this.datiDettaglio.MsgAvviso);

      // Immagine progetto
      this.setImage(this.datiDettaglio.PathImg);

      // Controllo progetto speciale
      this.switchSpeciale = this.getDatiProgetto().TipoProgetto == "speciale";

    },

    verificaSalva() {
      // Controllo modifiche
      if (this.modificaValori) {
        this.salva(false, true);

        // Reset
        this.modificaValori = false;

        return true;
      }

      // Controllo modifiche varianti produzione
      if (this.modificaValoriVarianti) {
        let filtro = this.datiVariantiProduzione;

        // Controllo tab battute: filtro solo x le macrocategorie di quella tab
        if (this.step == 2) {
          filtro = this.datiVariantiProduzione.filter(
            (x) =>
              x.CodMacroCategoria.toLowerCase() == "BATTUTA".toLowerCase() ||
              x.CodMacroCategoria.toLowerCase() ==
              "TIPOBATTUTA".toLowerCase() ||
              x.CodMacroCategoria.toLowerCase() ==
              "TIPO_MONTAGGIO".toLowerCase()
          );
        }

        // Salvo le varianti
        this.salvaVariantiProduzione(filtro, true);

        // Reset
        this.modificaValoriVarianti = false;

        return true;
      }

      return false;
    },

    async caricaImg() {
      this.attesaDisegno = true;

      // NOTA: Nel caso di progetto composto visualizzo in giallo la sezione
      let sez = this.datiSezioni.length > 1 ? this.sezione : 0;

      let param =
        this.idPrev + "/" + this.scheda + "/" + this.progDett + "/" + sez;

      try {
        await get_disegnoDettaglio(param);

        // Aggiorno il disegno
        this.setImage(this.datiDettaglio.PathImg);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaDisegno = false;
    },

    setImage(path) {
      this.pathImg = path + "?" + Math.random();
    },

    setDatiProgetto() {
      // Reset quote
      this.resetQuote();

      this.setImage(this.getDatiProgetto().PathImg);

      // Carico le sezioni
      this.caricaSezioni();
    },

    calcolaTotali() {
      get_totaliDettaglio(this.idPrev + "/" + this.scheda + "/" + this.progDett)
        .then((res) => {
          this.datiDettaglio.PrezzoGriglia = res.data.ImportoGriglia;
          this.datiDettaglio.Prezzo = res.data.ImportoUnitario;
          this.datiDettaglio.Importo = res.data.ImportoTotale;
        })
        .catch((err) => {
          console.log(this.$i2kFunctions.getErrorMsg(err));
        });
    },

    async caricaSerie() {
      // Controllo nuovo
      if (this.idPrev == 0) return;

      this.attesaSerie = true

      try {
        let res = await get_dettaglioSerie(this.idPrev);

        this.datiSerie = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaSerie = false

    },

    async caricaProgetti() {
      // Controllo nuovo
      if (this.idPrev == 0) return;
      // Controllo presenza serie
      if (!!this.datiDettaglio.CodSerie == false) return;

      this.attesaProgetti = true;

      // Reset
      this.datiProgetto = [];
      this.datiAperture = [];
      this.datiSezioni = [];
      this.datiAltManiglie = [];
      this.datiAvvisi = [];
      this.sezione = 0;
      this.resetQuote();

      try {
        let res = await get_dettaglioProgetti(
          this.idPrev + "/" + this.datiDettaglio.CodSerie
        );

        this.datiProgetto = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaProgetti = false;
    },

    resetQuote() {
      this.datiDettaglio.QuotaX1 = 0;
      this.datiDettaglio.QuotaX2 = 0;
      this.datiDettaglio.QuotaX3 = 0;
      this.datiDettaglio.QuotaY1 = 0;
      this.datiDettaglio.QuotaY2 = 0;
      this.datiDettaglio.QuotaY3 = 0;
    },

    caricaAperture(sezione) {
      // Ciclo sulle sezioni
      for (var i = 0; i < this.datiSezioni.length; i++) {
        // Controllo sezione attuale
        if (this.datiSezioni[i].Sezione == sezione) {
          this.datiAperture = this.datiSezioni[i].FiltroAperture.split(
            ";"
          ).filter((item) => item); // NOTA: filter serve per togliere i vuoti

          this.datiAltManiglie = this.datiSezioni[
            i
          ].FiltroAltezzeManiglie.split(";").filter((item) => item); // NOTA: filter serve per togliere i vuoti

          // Imposto l'apertura
          this.aperturaPred = this.datiSezioni[i].CodApertura;
          break;
        }
      }
    },

    prevStep() {
      if (this.verificaAvviso()) return;

      // NOTA: se ho attivato una sezione e vado indietro, resetto la sezione
      if (this.step == this.stepMax) {
        if (this.sezione != 0) this.impostaSezione(0);
      }

      this.step--;

      if (this.step == 1) {
        this.stepColor = "primary";
      } else if (this.step == 3) {
        if (this.visTabBattuta == false) this.step--;
        if (this.visTabCoprifili == false) this.step--;
      }
    },

    nextStep() {
      if (this.step == 1) {
        // ----------------
        // Tipologia
        // ----------------

        // Validazione input
        if (this.$refs.formDettaglio.validate() == false) {
          this.stepClickEnabled = false;
          return;
        }

        // Controllo modifica valori x risalvare
        if (this.verificaSalva()) {
          return;
        }

        // Controllo presenza quote sezioni
        if (this.datiSezioni.length > 1) {
          if (this.checkSezioniPresenti() == false) {
            this.dialogSezioni = true;
            return;
          }
        }

        // Controllo avviso non realizzabile
        if (this.verificaAvviso()) return;

        // Controllo reset valori (se passano da uno all'altro)
        if (this.datiDettaglio.CodProgetto == this.progettoLibero) {
          this.datiDettaglio.L = 0;
          this.datiDettaglio.H = 0;
        } else if (this.datiDettaglio.CodProgetto == this.progettoLibero_H) {
          this.datiDettaglio.L = 0;
        }

        this.stepColor = "green";
      } else if (this.step == 2) {
        // ----------------
        // Battuta profilo
        // ----------------

        // Validazione input
        if (this.$refs.formBattuteHeader.validate() == false) {
          this.stepClickEnabled = false;
          return;
        }
        if (this.$refs.formBattute.validate() == false) {
          this.stepClickEnabled = false;
          return;
        }

        // Controllo battuta superiore al massimo
        let maxBattuta = Math.max(
          ...this.getBattuteInserite().map((o) => parseInt(o.Valore)),
          0
        );
        let battuta = this.getValoreProduzione("VALORE_BATTUTA", "number");

        if (Number.isInteger(battuta)) {
          if (maxBattuta > battuta) {
            this.$toast.alert("Battuta superiore a " + battuta);

            this.stepClickEnabled = false;
            return;
          }
        }
        // ------

        // Controllo modifica valori x risalvare
        if (this.verificaSalva()) {
          return;
        }
      } else if (this.step == 3) {
        // ----------------
        // Coprifili
        // ----------------

        // Controllo modifica valori x risalvare
        if (this.verificaSalva()) {
          return;
        }
      } else if (this.step == this.stepMax) {
        // ----------------
        // Varianti
        // ----------------

        // Controllo modifica altezza maniglia
        if (
          this.selectRowSezione.AltezzaManigliaNative &&
          this.selectRowSezione.AltezzaManigliaNative !==
          this.selectRowSezione.AltezzaManiglia
        ) {
          this.selectRowSezione.AltezzaManiglia =
            this.selectRowSezione.AltezzaManigliaNative;
        }

        this.salva(true);
        return;
      }

      this.step++;

      // Controllo tab
      if (this.step == 2) {
        // Battuta
        if (this.visTabBattuta == false) this.step++;
      }
      if (this.step == 3) {
        // Coprifili
        if (this.visTabCoprifili == false) this.step++;
      }
      if (this.step == 4) {
        // Varianti
        // NOTA: sullo step varianti la sezione 11 è quella predefinita
        this.impostaSezione(11);
      }
    },

    inputChange($event) {
      this.modificaValori = true;
      this.stepClickEnabled = false;
    },

    inputChangeVarianti() {
      this.modificaValoriVarianti = true;
      this.stepClickEnabled = false;
    },

    rulesMisure(tipo) {
      if (this.datiDettaglio.L > 20000 || this.datiDettaglio.H > 20000)
        return [false]

      if (this.datiDettaglio.CodProgetto == "LIBERO") return [true];
      else if (this.datiDettaglio.CodProgetto == "LIBERO H" && tipo == "L")
        return [true];

      return this.numericRules;
    },

    verificaAvviso() {
      // Controllo avviso non realizzabile
      if (this.datiAvvisi.filter((f) => f.Grado > 0).length > 0) {
        this.$toast.alert("Soluzione non valida, impossibile proseguire");

        return true;
      }

      return false;
    },

    checkTipoMontaggio() {
      if (!this.datiDettaglio.CodSerie) return false;

      if (this.datiDettaglio.CodSerie == "PERSIANA") {
        if (
          this.getValoreProduzione("TIPO_MONTAGGIO").length == 0 ||
          this.getValoreProduzione("TIPO_MONTAGGIO") == "CARDINE"
        )
          return false;
      }

      return true;
    },

    getDatiProgetto() {
      return this.datiProgetto.find(
        (f) => f.CodProgetto == this.datiDettaglio.CodProgetto
      );
    },

    // --------------------------------------------------------
    // ------------------------ SEZIONI -----------------------
    // --------------------------------------------------------
    sezioni() {
      this.dialogSezioni = true;
    },

    async caricaSezioni() {
      // Controllo nuovo prev
      if (this.idPrev == 0) return;
      // Controllo presenza progetto
      if (!!this.datiDettaglio.CodProgetto == false) return;

      this.attesaProgetti = true;

      let param = this.idPrev + "/" + this.scheda + "/" + this.progDett; //+

      let data = {
        CodSerie: this.datiDettaglio.CodSerie,
        CodProgetto: this.datiDettaglio.CodProgetto,
      };

      try {
        let res = await get_sezioniDettaglio(param, data);

        this.datiSezioni = res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaProgetti = false;
    },

    caricaDatiSezione() {
      // Carico le aperture
      this.caricaAperture(this.sezione);

      // Reset
      this.selectRowSezione = [];

      // Ciclo sulle sezioni
      for (var i = 0; i < this.datiSezioni.length; i++) {
        if (this.datiSezioni[i].Sezione == this.sezione) {
          this.selectRowSezione = this.datiSezioni[i];
          break;
        }
      }
    },

    impostaSezione(sez) {
      this.sezione = sez;

      this.checkVarianti = [];

      this.caricaDatiSezione();

      if (this.datiSezioni.length == 1) return;

      this.caricaImg();
    },

    checkSezioniPresenti() {
      if (
        this.datiDettaglio.QuotaX1 == 0 &&
        this.datiDettaglio.QuotaX2 == 0 &&
        this.datiDettaglio.QuotaX3 == 0 &&
        this.datiDettaglio.QuotaY1 == 0 &&
        this.datiDettaglio.QuotaY2 == 0 &&
        this.datiDettaglio.QuotaY3 == 0
      ) {
        return false;
      }

      return true;
    },

    buttonQuoteVisible() {
      if (this.datiSezioni.length < 2) return false;

      // Controllo nuovo
      if (this.prog == 0 && this.checkSezioniPresenti() == false) return false;

      return true;
    },

    // --------------------------------------------------------
    // ------------------ VARIANTI  ---------------------------
    // --------------------------------------------------------
    zoomArticoli(macroCategoria, codVarProd, progVar, codArt, qta) {
      if (this.progDett == 0) {
        this.$toast.alert("Numero non valido");
        return;
      }

      // Memorizzo la colonna selezionata
      this.proprieta = macroCategoria;

      this.progVariante_insArticolo = progVar;
      this.codCategoriaVariante_insArticolo = codVarProd;
      this.codMacroCategoria_insArticolo = macroCategoria;
      this.codArticoloVariante = codArt;
      this.qtaVar = qta;

      // Apro dialog
      this.dialogArticoli = true;
    },

    async caricaVarianti() {
      // Reset
      this.datiVarianti = [];
      this.datiVariantiProduzione = [];
      this.checkVarianti = [];

      try {
        let res = await get_varianteDettaglio(
          this.idPrev + "/" + this.scheda + "/" + this.progDett
        );

        this.setDatiVarianti(res.data);
      } catch (error) {
        console.log(error);
      }
    },

    async salvaVarianti(data) {
      this.attesa = true;

      try {
        let res = await new_varianteDettaglio(data);

        this.setDatiVarianti(res.data);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    salvaVariantiProduzione(data, stepAvanti) {
      this.attesa = true;

      new_varianteProduzioneDettaglio(data)
        .then((res) => {
          this.setDatiVarianti(res.data);

          // Controllo avanzamento form
          if (stepAvanti) this.nextStep();
        })
        .catch((err) => {
          console.log(this.$i2kFunctions.getErrorMsg(err));
        })
        .finally(() => {
          // Fine attesa
          this.attesa = false;
        });
    },

    salvaVariantiMultiple(data) {
      this.attesa = true;

      new_varianteDettaglioMultiple(data)
        .then((res) => {
          this.setDatiVarianti(res.data);
        })
        .catch((err) => {
          console.log(this.$i2kFunctions.getErrorMsg(err));
        })
        .finally(() => {
          // Fine attesa
          this.attesa = false;
        });
    },

    setDatiVarianti(data) {
      this.datiVarianti = data.Varianti;
      this.datiVariantiProduzione = data.VariantiProduzione;

      this.datiDettaglio.PrezzoGriglia = data.Totali.ImportoGriglia;
      this.datiDettaglio.Prezzo = data.Totali.ImportoUnitario;
      this.datiDettaglio.Importo = data.Totali.ImportoTotale;

      this.datiDettaglio.Uw = data.Dettaglio.Uw;

      // Immagine progetto
      this.caricaImg();

      // Controllo avvisi
      if (!!data.Avvisi && Object.keys(data.Avvisi).length > 0 && data.Avvisi.Descrizione) {
        // Controllo messaggio che richiede conferma
        if (data.Avvisi.RichiediConferma)
          this.$toastFixed.alert(data.Avvisi.Descrizione);
        else
          this.$toast.alert(data.Avvisi.Descrizione);
      }

      // NOTA: devo aggiornare il dettaglio
      this.caricaGrigliaDett = true;
    },

    modificaVariante() {
      if (this.checkVarianti == false) {
        this.$toast.alert("Articolo non selezionato");
        return;
      }

      // Controllo esterno
      let role = this.$store.getters["user/getRole"];

      // Gli utenti esterni possono modificare solo la libera
      if (
        role.toLowerCase() !== "Interno".toLowerCase() &&
        role.toLowerCase() !== "AgenteInterno".toLowerCase() &&
        this.var_selezionata.CodArticolo.toUpperCase() != "LIBERA"
      ) {
        this.$toast.alert("Articolo non modificabile");
        return;
      }

      this.dialogModificaVar = true;
      return;
    },

    async eliminaVariante() {
      if (this.checkVarianti == false) {
        this.$toast.alert("Articolo non selezionato");
        return;
      }

      // Controllo anta ribalta
      if (this.var_selezionata.CodMacroCategoria == "ANTA_RIBALTA") {
        this.$toast.alert(
          "L'anta ribalta deve essere modificata dall'apertura"
        );
        return;
      }

      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare la variante selezionata ?"
      );

      if (!confirm) return;

      this.attesa = true;

      try {
        let res = await del_varianteDettaglio(
          this.idPrev +
          "/" +
          this.scheda +
          "/" +
          this.progDett +
          "/" +
          this.var_selezionata.ProgVariante
        );

        if (res.data != "OK") {
          this.$toast.alert(res.data);
        }

        // Ricarico i dati
        await this.caricaVarianti();
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;
    },

    getValoreProduzione(codVar, type) {
      if (this.datiVariantiProduzione.length == 0) return "";

      let v = this.datiVariantiProduzione.find(
        (f) => f.CodVarianteProduzione == codVar
      );

      if (v) {
        if (type === "number") return parseInt(v.Valore);
        else return v.Valore;
      }

      return "";
    },

    getValoreVariante(codVarProd) {
      if (!this.datiVarianti) return "";

      let progVarObj = this.datiVariantiProduzione.find(
        (f) => f.CodVarianteProduzione == codVarProd
      );

      if (!progVarObj) return "";

      let v = this.datiVarianti.find(
        (f) => f.ProgVariante == progVarObj.ProgVariante
      );

      return v ? v.CodArticolo : "";
    },

    setValoreProduzione(variante, valore) {
      let arr = this.datiVariantiProduzione.find(
        (f) => f.CodVarianteProduzione == variante
      );

      arr.Valore = valore;

      this.inputChangeVarianti();
    },

    getProgVariante(macroCat) {
      let v = this.datiVarianti.find((f) => f.CodMacroCategoria == macroCat);

      return v ? v.ProgVariante : 0;
    },

    getVarianteProduzione(macroCat) {
      return this.datiVariantiProduzione.find(
        (f) => f.CodMacroCategoria == macroCat
      );
    },

    getProgVarianteProduzione(tipo) {
      let v = this.datiVariantiProduzione.find(
        (f) => f.CodVarianteProduzione == tipo
      );
      return v.ProgVariante;
    },

    getVariantiProduzione(macroCat) {
      return this.datiVariantiProduzione.filter(
        (f) => f.CodMacroCategoria == macroCat
      );
    },

    impostaTipoBattuta(codVar, v) {

      // Ricavo la variante di produzione 
      let varProd = this.datiVariantiProduzione.find(
        (f) => f.CodVarianteProduzione == codVar
      );

      if (!!varProd == false) return;

      // Memorizzo il valore
      varProd.Valore = v;

      let battuta = this.getValoreProduzione("VALORE_BATTUTA");

      // Controllo azzeramento battuta inf
      if (this.getValoreProduzione("TIPO_MONTAGGIO") != "TELAIO4LATI") {
        this.setValoreProduzione("BATTUTA_INF", 0);
      } else {
        this.setValoreProduzione("BATTUTA_INF", battuta);
      }

      // Forzo l'aggiornamento
      this.inputChangeVarianti();
    },

    impostaBattute(codVar, v) {
      let varProd = this.datiVariantiProduzione.find(
        (f) => f.CodVarianteProduzione == codVar
      );

      if (!!varProd == false) return;

      // Memorizzo il valore
      varProd.Valore = v;

      // Filtro le battute (sup,dx,inf,sx)
      let arr = this.getBattuteInserite();

      let battuta = this.getValoreProduzione("VALORE_BATTUTA");

      // Memorizzo il valore
      for (let item of arr) {
        item.Valore = battuta;
      }

      this.inputChangeVarianti();
    },

    getBattuteInserite() {
      return this.datiVariantiProduzione.filter(
        (f) => f.CodMacroCategoria == "BATTUTA"
      );
    },

    impostaValoreBattute(codVar, v) {
      this.setValoreProduzione(codVar, v)

      let tipo = "Interno"
      let row = this.datiBattute.find(
        (f) => f.Tipo.toLowerCase() == tipo.toLowerCase() && f.CodTelaio == v
      );

      this.impostaBattute('VALORE_BATTUTA', row.Battuta)
    },

    async impostaCoprifili(tipo, valore, par) {
      let res = false;

      // Controllo solo su coprifili SUP se applicare il valore a tutti
      if (tipo == "COPRIFILO_I_SUP" || tipo == "COPRIFILO_E_SUP") {
        res = await this.$confirm.open(
          "Coprifili",
          "Vuoi impostare il valore su tutti i coprifili ?"
        );
      }

      // Controllo salvataggio singolo coprifilo
      if (!res) {
        // Ricavo la variante coprifilo
        let arr = par.VariantiProduzione.filter(
          (f) => f.CodVarianteProduzione == tipo
        );

        // Imposto il valore selezionato
        for (let item of arr) {
          item.Valore = valore;
        }

        par.VariantiProduzione = arr;

        this.salvaVarianti(par);
        return;
      }

      // Ricavo il filtro x "Coprifilo"
      let filtro = tipo.replace("_SUP", "");

      // Ricavo le varianti che includono coprifilo
      let arr = this.datiVariantiProduzione.filter((f) =>
        f.CodVarianteProduzione.includes(filtro)
      );

      // Imposto il valore selezionato
      for (let item of arr) {
        item.Valore = valore;
      }

      this.salvaVariantiMultiple({
        Variante: par.Variante,
        VariantiProduzione: arr,
      });
    },

    apriEliminaCoprifili(tipo) {
      this.tipoCoprifiliElimina = tipo;
      this.dialogCoprifili = true;
      return;
    },

    async eliminaCoprifili(tipo, selezionati) {

      let nome = "COPRIFILO_" + (tipo == "interni" ? "I" : "E");

      // Filtro su "COPRIFILO_I / COPRIFILO_E" e verifico che siano impostati (ossia legati a variante)
      let filtro = this.datiVariantiProduzione.filter(
        (f) => f.CodVarianteProduzione.includes(nome) && f.ProgVariante != 0
      );

      if (Object.keys(filtro).length == 0) return;

      let arrCoprifili = [];
      for (let i in filtro) {
        if (filtro[i].ProgVariante == 0) continue;

        // Controllo se è presente tra quelli selezionati
        if (
          selezionati.indexOf(filtro[i].CodVarianteProduzione.split("_")[2]) ==
          -1
        )
          continue;

        if (arrCoprifili.indexOf(filtro[i].ProgVariante) == -1)
          arrCoprifili.push(filtro[i].ProgVariante);
      }

      this.attesa = true;

      try {
        // Ciclo sui coprifili da eliminare
        for (let itemProgVar of arrCoprifili) {
          let res = await del_varianteDettaglio(
            this.idPrev +
            "/" +
            this.scheda +
            "/" +
            this.progDett +
            "/" +
            itemProgVar
          );

          if (res.data != "OK") {
            this.$toast.alert(res.data);
          }
        }

        // Ricarico i dati
        await this.caricaVarianti();
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;
    },

    apriPariCodice(tipo) {
      if (tipo == 'articolo' && this.checkVarianti == false) {
        this.$toast.alert("Articolo non selezionato");
        return;
      }

      this.tipoPariCodice = tipo
      this.dialogPariCodice = true;
    },

    setVarSelezionata(item) {
      if (this.checkVarianti == false) return;

      // Reset e inserimento singola voce
      this.checkVarianti = [];
      this.checkVarianti.push(item.ProgVariante);

      this.var_selezionata = Object.assign({}, item);
    },

    //---- CLOSE DIALOG ---- //
    async chiudi() {
      // NOTA: se altre dialog sono aperte, se premo esc agganciano l'evento keydown
      if (this.dialogVariante == true) return;
      if (this.dialogSezioni == true) return;
      if (this.dialogArticoli == true) return;

      // Controllo fattibilità (non lascio chiudere)
      // 30/10/2020 tolto il blocco, ho messo il messaggio sotto
      /*  if (this.verificaAvvisoNonRealizzabile()) return;
      if (this.verificaAvvisoNonValido()) return; */

      // Verifica avvisi fattibilità
      if (this.verificaAvviso()) {
        let res = await this.$confirm.open(
          "Attenzione",
          "La soluzione contiene errori, vuoi chiudere lo stesso ?"
        );

        if (!res) return;
      }

      this.$emit("chiudiDialog", this.caricaGrigliaDett);

      // Reset
      this.caricaGrigliaDett = false;
    },

    closeDialogSezioni(aggiorna, quote, noteQuote) {
      this.dialogSezioni = false;

      // Controllo aggiorna
      if (aggiorna) {
        this.datiDettaglio.QuotaX1 = quote.x[0];
        this.datiDettaglio.QuotaX2 = quote.x[1];
        this.datiDettaglio.QuotaX3 = quote.x[2];

        this.datiDettaglio.QuotaY1 = quote.y[0];
        this.datiDettaglio.QuotaY2 = quote.y[1];
        this.datiDettaglio.QuotaY3 = quote.y[2];

        this.datiDettaglio.NoteQuote = noteQuote;

        // Aggiorno il disegno
        this.salva(false);
      }
    },

    closeDialogArticoli(aggiorna, codArtObj, par) {
      this.dialogArticoli = false;

      // Controllo se ho scelto un valore
      if (par) {
        let macroCat = this.codMacroCategoria_insArticolo
          .toString()
          .toLowerCase();

        // Controllo soglia (deve registrare anche i valori delle battute)
        if (macroCat == "SOGLIA".toLowerCase()) {
          // SOGLIA
          this.setValoreProduzione("BATTUTA_INF", 0);
          this.salvaVariantiProduzione(this.getVariantiProduzione("BATTUTA"));
        } else if (macroCat == "COPRIFILO".toLowerCase()) {
          // COPRIFILI
          this.impostaCoprifili(
            this.codCategoriaVariante_insArticolo,
            codArtObj.CodArticolo,
            par
          );
          return;
        }
      }

      // Controllo salvataggio
      if (aggiorna) this.salvaVarianti(par);
    },

    closeDialogPariCodice(cont) {
      this.dialogPariCodice = false;

      if (cont) {
        this.$toast.alert("Posizioni elaborate: " + cont);
        this.caricaGrigliaDett = true;
        this.caricaVarianti();
      }
    },

    closeDialogCoprifili() {
      this.dialogCoprifili = false;
    },

    closeDialogVar(aggiorna) {
      this.dialogModificaVar = false;

      if (aggiorna) {
        this.caricaGrigliaDett = true;
        this.caricaVarianti();
      }
    },


    apriLink() {

      if (!!this.getLinkInfo() == false) return;

      let res = this.datiSerie.find(
        (v) => v.CodSerie === this.datiDettaglio.CodSerie
      );

      window.open(res.LinkTooltip, "_blank");
    },

    getLinkInfo() {
      let res = this.datiSerie.find(
        (v) => v.CodSerie === this.datiDettaglio.CodSerie
      );

      return !!res && Object.keys(res).length > 0 && res.LinkTooltip

    }
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    visualProg() {
      return this.progDett == 0 ? "" : this.progDett;
    },

    getVariantiSezione() {
      // NOTA: le varianti che hanno la sezione a 0 non le visualizzo (es. Coprifili)
      // NOTA: le varianti nascoste non le visualizzo
      return this.datiVarianti.filter(
        (v) => v.Sezione === this.sezione && v.NascondiVariante == false
      );
    },

    buttonSezioni: {
      get: function () {
        return this.datiSezioni.findIndex((f) => f.Sezione == this.sezione);
      },
      set: function (val) {
        this.impostaSezione(this.datiSezioni[val].Sezione);
      },
    },

    getListaBattute() {
      let tipo = "Interno"

      return this.datiBattute.filter(
        (f) => f.Tipo.toLowerCase() == tipo.toLowerCase()
      );
    },

    getTipoTelaio() {
      let tipo = "Interno"

      let res = this.datiBattute.filter(
        (f) => f.Tipo.toLowerCase() == tipo.toLowerCase()
      );


      let valoreMem = this.getValoreProduzione('TIPO_BATTUTA')
      // Se il valore non è più prensete, lo inserisco nella tendina
      if (res.findIndex(f => f.CodTelaio == valoreMem) == -1) {
        res.push({ Tipo: 'Interno', Battuta: 0, CodTelaio: valoreMem })
      }


      return res
    },

    checkSoglia() {
      return !!this.datiVariantiProduzione.find(
        (f) =>
          f.CodVarianteProduzione == "TIPO_MONTAGGIO" &&
          (f.Valore == "SOGLIA" || f.Valore == "TELAIO3LATI")
      );
    },

    getTipoMontaggio() {
      let res = this.getDatiProgetto();

      if (!!res == false) return [];

      return this.getDatiProgetto().FiltroTipoMontaggio.split(";");
    },

    getElementiNascostiSerieSel() {

      let res = this.datiSerie.find(f => f.CodSerie == this.datiDettaglio.CodSerie)

      return res ? res.NascondiElementiWeb : ""
    },

    visTabBattuta() {
      return !this.getElementiNascostiSerieSel.includes("SchedaBattutaProfilo|");
    },

    visTabCoprifili() {
      return (
        !this.getElementiNascostiSerieSel.includes("SchedaCoprifili|") &&
        this.getValoreProduzione("TIPO_MONTAGGIO") != "CARDINE"
      );
    },

    visTipoBatt() {

      return (
        this.getValoreProduzione("TIPO_MONTAGGIO") != "CARDINE" &&
        !this.getElementiNascostiSerieSel.includes("TipoBattuta|")
      );
    },

    visTipoMontaggio() {
      return !this.getElementiNascostiSerieSel.includes('TipoMontaggio|')
    },

    visBatt() {
      return !this.getElementiNascostiSerieSel.includes('Battuta|')
    },

    visFormBatt() {
      return this.checkTipoMontaggio && this.visBatt
    },

    visFormCoprifiliInterni() {
      return !this.getElementiNascostiSerieSel.includes('CoprifiliInterni|')
    },

    visFormCoprifiliEsterni() {
      return !this.getElementiNascostiSerieSel.includes('CoprifiliEsterni|')
    },

    getStepper() {
      let arr = [...this.stepper];

      if (this.visTabBattuta == false) {
        arr = arr.filter((f) => f != "Battuta profilo");
      }

      if (this.visTabCoprifili == false) {
        arr = arr.filter((f) => f != "Coprifili");
      }

      return arr;
    },

    getStep() {
      let s = this.step;
      if (this.step == 4) {
        if (this.visTabBattuta == false) return s--;
        if (this.visTabCoprifili == false) return s--;
      }

      return s;
    },

    getProgetti() {
      return this.datiProgetto.filter(
        (x) => x.TipoProgetto == (this.switchSpeciale ? "speciale" : "regolare")
      );
    },

    nascondiManiglia() {
      // Controllo libera
      if (this.checkLibera) return true;

      // Controllo tipo apertura
      let data = this.datiSezioni.find((v) => v.Sezione === this.sezione);

      if (data) {
        return data.CodApertura == "FX" || data.CodApertura == "W";
      }

      return false;
    },

    scrittaSoglia() {
      if (this.getValoreProduzione("TIPO_MONTAGGIO") == "SOGLIA")
        return "SOGLIA";

      return "";
    },

    checkLibera() {
      return this.datiDettaglio.CodSerie === "LIBERA" || this.datiDettaglio.CodSerie === "LIBERA INT";
    },
  },

  watch: {
    dialog(val) {
      if (val) this.caricaDati();
    },
  },
};
</script>

<style scoped>
/* Tabella varianti */
table.tableVarianti {
  border-spacing: 0px;
}

table.tableVarianti td,
table.tableVarianti th {
  /* height: 20px; */
  border-bottom: 1px solid lightgray !important;
}

table.tableVarianti td {
  padding: 3px;
}

table.tableVarianti th {
  font-weight: bold;
}

table.tableVarianti td:first-child {
  width: 100px;
  /*  border-right: 1px solid lightgray !important; */
  font-weight: bold;
  padding-left: 10px;
  font-size: 12px;
}

table.tableVarianti td:nth-child(2) {
  width: 25px;
  /* border-right: 1px solid lightgray !important; */
  padding: 0px;
  margin: auto;
  padding-left: 7px;
}

table.tableVarianti td+td+td {
  width: 520px;
}

/* ---------- */

.altezzaContainer {
  height: 500px !important;
}

.divLabel {
  width: 100px;
  height: 55px;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.flexStyle {
  border-bottom: 1px solid lightgray;
  margin-top: 3px;
  max-height: 60px !important;
}
</style>
